@font-face {
  font-family: Montserrat_Bold;
  src: url("/src/fonts/Montserrat-Black.ttf");
}
@font-face {
  font-family: Montserrat_Light;
  src: url("/src/fonts/Montserrat-Light.ttf");
}
@font-face {
  font-family: "Montserrat_Regular";
  src: url("/src/fonts/Montserrat-Regular.ttf");
}
@font-face {
  font-family: Montserrat_SemiBold;
  src: url("/src/fonts/Montserrat-SemiBold.ttf");
}
@font-face {
  font-family: Montserrat_Medium;
  src: url("/src/fonts/Montserrat-Medium.ttf");
}

@font-face {
  font-family: "Montserrat";
  src: url("/src/fonts/Montserrat-Regular.ttf");
}

/* Start of Nav Style */

#root::-webkit-scrollbar{
    width:0.7291666666666666vw
}

body{
  overflow-x: hidden;
}

.sideNav{
  height:100%; /* Full-height: remove this if you want "auto" height */
  width: 14.85vw; /* Set the width of the sidebar */
  position: fixed; /* Fixed Sidebar (stay in place on scroll) */
  z-index: 1; /* Stay on top */
  top: 0; /* Stay at the top */
  left: 0;
  background-color: #0e0e0e; /* Black */
  overflow-x: hidden; /* Disable horizontal scroll */
  padding-top: 20px;
}

.box {
  position: absolute;
  top: 16.649214659685864vh;
  left: 0.78125vw;
}

#logo {
  position: absolute;
  top: 5.09424vh;
  left: 6.2vw;
}

.currentItem {
  width: 13.020833333333334vw;
  height: 6.0732984293193715vh;
  background-color: white;
  font-size: 0.8333333333333334vw;
  border-radius: 5px;
  text-align: center;
  text-decoration: none;
  color: #0e0e0e;
  font-family: Montserrat;
}

.Item {
  width: 13.020833333333334vw;
  font-size: 0.8333333333333334vw;
  height: 6.0732984293193715vh;
  border-radius: 5px;
  text-align: center;
  text-decoration: none;
  color: white;
  font-family: Montserrat;
}

#NavIcon {
  position: absolute;
  left: 0.5208333333333334vw;
}

#NavIconCurr {
  position: absolute;
  left: 0.5208333333333334vw;
  color: black;
  z-index: 1;
}

#newEvent {
  background-color: #0d6efd;
  position: absolute;
  top: 91.09947643979058vh;
  left: 0.8854166666666666vw;
}

#newEventText {
  position: absolute;
  left: 4.947916666666667vw;
}

#EventIcon {
  position: absolute;
  left:  1.8229166666666667vw;
  top: 2.094240837696335vh;
}


/* End of Nav Style */

/* Start of SearchBar style */
.searchBar {
  width: 85vw;
  border: 1px solid;
  border-color: #6a6a6a;
  box-sizing: border-box;
  height: 7.952286282306163vh;
  position: absolute;
  left: 14.9vw;
  top: 0px;
  background-color: #0e0e0e;
  overflow-x: hidden;
  overflow-y: hidden;
}

#search {
  height: 3.6649214659685865vh;
  width: 15.46875vw;
  background-color: #c8c8c8;
  color: #929292;
  border-color: #2c2c2c;
  border-radius: 5px;
  position: relative;
  border-style: none;
  font-family: Montserrat_Medium;
  text-indent: 10px;
  left: 101px;
  top: 22px;
}

#searchIcon {
  z-index: 1;
  color: #929292;
  position: absolute;
  top: 30px;
  left: 360px;
}

#UserIcon {
  width: 4.712041884816754vh;
  height: 4.712041884816754vh;
  border-radius: 50%;
  background-color: #858484;
  color: white;
  border-radius: 50%;
  text-indent: 10px;
}

#logoutBtn {
  background-color: #858484;
  color: white;
  width: 4.712041884816754vh;
  height: 4.712041884816754vh;
  border-radius: 50%;
  border: none;
}

/* End of SearchBar Style */

/* Start of Content Style */
.app {
  background-color: #1b1b1b;
}

@media only screen and (min-width: 765px) {
  .app {
    background-color: #2c2c2c;
    display: flex;
    justify-content: center;
  }
}

.content {
  background-color: #2c2c2c;
}

@media only screen and (min-width: 765px) {
  .content {
    width: 765px;
  }
}

#content-titel-text {
  color: #656565;
  font-family: Montserrat_Regular;
  font-size:  0.7291666666666666vw;
  position: absolute;
  left: 3.6458333333333335vw;
  top: 0.5vh;
}

#comingSoonText {
  color: white;
  font-family: Montserrat;
  text-align: center;
  position: absolute;
  top: 20px;
  left: 320px;
}

.StatsBox {
  /* display: none; */
  display: flex;
  position: absolute;
  right: 50%;
  top: 1.5vh;
  transform: translate(50%);
}

.StatsBox > .Stats {
  margin: 31.5px;
}

.Stats {
  border-radius: 7px;
  width: 15.625vw;
  height: 12.226640159045726vh;
  background-color: #0e0e0e;
  z-index: 1;
}

.StatsIcon {
  width: 5.467196819085487vh;
  height: 5.467196819085487vh;
  border-radius: 50%;
  background-color: #0d6efd;
  position: relative;
  left: 1.0416666666666667vw;
  top: 3.479125248508946vh;
  font-size: 1.3020833333333333vw;
}

#StatsIcon {
  position: relative;
  left: 0.6510416666666666vw;
  top: 1.4910536779324055vh;
  color: white;
}

#StatsAnzahl {
  color: white;
  font-size: 1.25vw;
  font-family: Montserrat;
  position: relative;
  left: 4em;
  top: -2em;
}

#StatsTitel {
  color: #656565;
  font-size: 0.7291666666666666vw;
  font-family: Montserrat;
  position: relative;
  left: 6.8em;
  top: -5em;
}

.nextEventBox {
  width: 44.791666666666664vw;
  height: 25.24850894632207vh;
  background-color: #0e0e0e;
  position: absolute;
  left: 8.3vw;
  top: 19.880715705765407vh;
  border-radius: 7px;
}

#nextEventText {
  color: white;
  font-family: Montserrat;
  font-size: 0.9375vw;
  position: absolute;
  top: -1.2922465208747516vh;
  left: 1.0416666666666667vw;
  z-index: 1;
}

#nextEventDatum {
  color: #0d6efd;
  font-size: 0.625vw;
  font-family: Montserrat;
  font-weight: 500;
  font-style: bold;
  position: absolute;
  top: 13.916500994035784vh;
  right: 3em;
  z-index: 1;
}

#nextEventDatumNum {
  color: white;
  font-family: Montserrat_Light;
  font-size: 0.7291666666666666vw;
  position: absolute;
  top: 15.109343936381709vh;
  right: 1.5em;
  z-index: 1;
}

.eventDetailsBox {
  width: 44.791666666666664vw;
  height: 10.934393638170974vh;
  background-color: #0e0e0e;
  position: absolute;
  bottom: 0px;
  border-radius: 7px;
}

#eventDetailsText {
  width: 39.0625vw;
  color: white;
  font-family: Montserrat;
  font-size: 1.25vw;
  z-index: 1;
  position: absolute;
  top: -1em;
  left: 1em;
}

#eventDetailsTextDetails {
  font-family: Montserrat;
  font-size: 0.7291666666666666vw ;
  color: #656565;
  position: absolute;
  left: 26.5em;
  top: 19.8vh;
}

#aufrufeIcon {
  color: #656565;
  z-index: 1;
  position: absolute;
  left: 1.3020833333333333vw;
  top: 7.5546719681908545vh;
  font-size:0.8vw;
}

#geteiltIcon {
  font-size: 0.8vw;
  color: #656565;
  z-index: 1;
  position: absolute;
  top: 21.868787276341948vh;
  left: 7.03125vw;
}

#gespeichertIcon {
  font-size:0.8vw;
  color: #656565;
  z-index: 1;
  position: absolute;
  top: 21.868787276341948vh;
  left: 12.239583333333334vw;
}

#nextEventAufrufe {
  color: #656565;
  z-index: 1;
  position: absolute;
  left: 2.6041666666666665vw;
  top: 19.880715705765407vh;
  font-size:0.7291666666666666vw ;
}

.ProgressBar1 {
  width: 200px;
  border-radius: 10px;
  border-color: none;
  position: absolute;
  right: 3em;
  top: 11.5em;
  z-index: 1;
  color: #0d6efd;
}

#ticketsText {
  font-family: Montserrat;
  color: #686767;
  font-size: 13px;
  position: absolute;
  top: 145px;
  right: 190px;
}

#ticketsAnzahl {
  font-family: Montserrat;
  color: #686767;
  position: absolute;
  top: 216px;
  right: 149px;
  font-size: 11px;
}

#ticketsGeld {
  font-family: Montserrat_Regular;
  color: white;
  position: absolute;
  top: 145px;
  right: 60px;
  font-size: 13px;
}

.eventErstellen {
  width: 21.354166666666668vw;
  height: 25.24850894632207vh;
  background-color: #0e0e0e;
  border-radius: 7px;
  position: absolute;
  left: 55.3vw;
  top: 19.880715705765407vh;
}

#erstellenDetails {
  font-family: Montserrat_Light;
  color: white;
  position: relative;
  top: 5.964214711729622vh;
  font-size: 0.8333333333333334vw;
  text-align: center;
}

.Uebersicht {
  width: 70.41666666666667vw;
  height: 34.791252485089466vh;
  display: flex;
  position: absolute;
  /* top: 45.2286282306163vh; */
  /* left: 4.270833333333333vw; */
  top:70%;
  left:50%;
  transform: translate(-50%,-50%);
  border-radius: 7px;
}

.Uebersicht > .UebersichtItem {
  margin: 20px;
}

.UebersichtItem {
  width: 21.875vw;
  height: 37.77335984095428vh;
  background-color: #0e0e0e;
  border-radius: 7px;
}


.uebersichtTitelDetails {
  color: #0d6efd;
  font-size: 0.7291666666666666vw;
  font-family: Montserrat;
  position: relative;
  left: 15.885416666666666vw;
  top: -4.8vh;
  width: 5.208333333333333vw;
}

#eventImageProfil {
  object-fit: cover;
  position: absolute;
  width:100%;
  top:5.1493305870236865vh;
  height:24.6vh;
}

.ProfilAvatar {
  width: 7.8125vw;
  height: 14.910536779324056vh;
  border-radius: 50%;
  background-color: #c4c4c4;
  position: relative;
  top: 27.335984095427435vh;
  left: 5.78125vw;
}

#VeranstalterName {
  color: white;
  font-family: Montserrat;
  position: absolute;
  top: 40.75546719681908vh;
  left: 5.520833333333333vw;
  font-size: 2.5vw;
}

#VeranstalterBezeichnung {
  color: #929292;
  font-size: 1.25vw;
  position: absolute;
  top: 49.70178926441352vh;
  left: 5.625vw;
  font-family: Montserrat_Light;
}

.Profilbox {
  width: 41.666666666666664vw;
  height: 81.51093439363817vh;
  display: flex;
  flex-wrap: wrap;
  position: absolute;
  top: 56.66003976143141vh;
  left: 5.208333333333333vw;
}

.Profilbox > div {
  margin: 10px;
}

.VeranstalterBeschreibung {
  width: 41.666666666666664vw;
  height: 21.570576540755468vh;
  background-color: #0e0e0e;
  border-radius: 7px;
  position: relative;
  left: 0px;
  top: 0px;
}

#beschreibungtitel {
  color: white;
  font-family: Montserrat;
  font-size: 0.9375vw;
  margin: 20px;
}

#ProfilBeschreibung {
  width: 31.25vw;
  color: #ffffff;
  font-family: Montserrat_Light;
  font-size: 0.7291666666666666vw;
  font-weight: lighter;
  position: absolute;
  top: 4.970178926441352vh;
  left: 2.0833333333333335vw;
}

.editProfile {
  width: 14.583333333333334vw;
  height: 5.7654075546719685vh;
  background-color: #0d6efd;
  position: absolute;
  top: 44.23459244532803vh;
  right: 9.114583333333334vw;
  color: white;
  font-size: 1.25vw;
  font-family: Montserrat;
  border-radius: 5px;
  text-align: center;
}

.editProfileText{
  position: absolute;
  top: 5%;
  left: 50%;
  transform: translate(-50%,-50%);
}

#editIcon {
  position: absolute;
  left: 1.0416666666666667vw;
  top: 1.9880715705765408vh;
}

.VorschauContainer {
  width: 26.041666666666668vw;
  height: 59.642147117296226vh;
  border-radius: 7px;
  background-color: #0e0e0e;
  position: absolute;
  top: 57.65407554671968vh;
  left: 49.479166666666664vw;
}

.VorschauIFrame {
  border-radius: 7px;
  border: none;
  position: relative;
  left: 50%;
  top: 40%; 
  transform: translate(-50%, -42%);
}



/* #vorschauImage {
  position: absolute;
  top: 50px;
  left: 50px;
} */

.SocialMedia {
  border-radius: 7px;
  width: 18.75vw;
  height: 30.119284294234593vh;
  background-color: #0e0e0e;
  position: relative;
  top: -9.940357852882704vh;
  left: 0vw;
}
#SocialMediatitel {
  color: white;
  font-family: Montserrat;
  font-size: 0.625vw;
  position: relative;
  left: 1.5625vw;
}

#instagram {
  position: absolute;
  left: 1.5625vw;
}

.Kontakt {
  width: 18.75vw;
  height: 30.119284294234593vh;
  background-color: #0e0e0e;
  position: relative;
  top: -9.940357852882704vh;
  left: 2.0833333333333335vw;
  border-radius: 7px;
  display: flexbox;
}

.Kontakt > .KontaktDatenItem {
  margin: 30px;
  position: relative;
  left: 0.5208333333333334vw;
  line-height: 0.4970178926441352vh;
}

.KontaktDatenItem {
  position: absolute;
  top: -0.9940357852882704vh;
}

#KontaktDatenItemText {
  color: white;
  font-family: Montserrat_Light;
  font-size: 0.6770833333333334vw;
  font-weight: lighter;
  position: absolute;
  top: 0vh;
  left: 2.6041666666666665vw;
}

#SocialMediaIcon {
  color: black;
  position: absolute;
  top: 1.1928429423459244vh;
  left: 1.0416666666666667vw;
  font-size:1.3541666666666667vw;
}

#SocialMediaLink {
  text-decoration: none;
  color: #0e0e0e;
  font-family: Montserrat_Regular;
  text-align: start;
}

#SocialMediaLinkText {
  text-indent: 2.6041666666666665vw;
  position: relative;
  top: 50%;
  left: 50%;
  transform: translate(-50%,-50%);
  /* top: 1.4910536779324055vh; */
  /* left: 1.0416666666666667vw; */
  font-size: 0.9791666666666666vw;
}

#InstaLink {
  width: 15.625vw;
  height: 4.970178926441352vh;
  background-color: white;
  position: absolute;
  left: 1.5625vw;
  border-radius: 5px;
}

#YoutubeLink {
  width: 15.625vw;
  height: 5.467196819085487vh;
  background-color: white;
  position: relative;
  top: 9.940357852882704vh;
  left: 1.5625vw;
  border-radius: 5px;
}

#KontaktDatenIcon {
  color: white;
}

.ProfilBearbeitenBox {
  width: 77.08333333333333vw;
  height: 13.916500994035784vh;
  background-color: #1b1b1b;
  border-radius: 5px;
  position: relative;
  top: 4.970178926441352vh;
  left: 3.6458333333333335vw;
}

#BoxHeaderTitle {
  width: 57.291666666666664vw;
  color: white;
  font-family: Montserrat_Regular;
  font-size: 1.875vw;
  position: absolute;
  left: 2.6041666666666665vw;
  font-weight: 600;
}

#BoxHeaderTitleDetails {
  color: #929292;
  font-family: Montserrat;
  font-weight: 400;
  font-size: 0.7291666666666666vw;
  position: absolute;
  left: 2.7604166666666665vw;
  top: 6.958250497017892vh;
}

.abbruchBtn {
  width: 10.416666666666666vw;
  height: 4.970178926441352vh;
  background-color: #656565;
  border-radius: 5px;
  position: absolute;
  top: 4.473161033797217vh;
  left: 60.416666666666664vw;
  text-indent: 33%;
}

#abbruch {
  color: white;
  font-size: 0.78125vw;
  font-family: Montserrat_Light;
  font-weight: 600;
  position: relative;
}

.SideBar {
  position: absolute;
  top: -9.940357852882704vh;
  left: 3.6458333333333335vw;
}

.Inputs {
  position: absolute;
  top: -9.940357852882704vh;
  left: -6.25vw;
}

.Footer {
  position: absolute;
  top: 196.81908548707753vh;
  left: 41.40625vw;
}

#lasttext {
  color: white;
  font-size: 1.0416666666666667vw;
  width: 20.833333333333332vw;
  font-family: Montserrat_Light;
  font-weight: 400;
  position: relative;
  top: 11.928429423459244vh;
  left: 8.333333333333334vw;
}

.v3 {
  border: 2px solid #a5a5a5;
  width: 57.8125vw;
  position: relative;
  left: 27.6vw;
  top:220vh;
}

.anstehendeEvents {
  width: 48.2vw;
  height: 97.4155069582505vh;
  position: absolute;
  top: 19.880715705765407vh;
  left: 3.6458333333333335vw;
  border-radius: 7px;
  background-color: #1b1b1b;
}

.EventBox::-webkit-scrollbar{
  width:10px;
}

.EventBox::-webkit-scrollbar-thumb{
  background: white;
  border-radius: 20px;
}

.EventBox::-webkit-scrollbar-track{
  background-color: black;
  border-radius: 20px;
  border:4px solid black;
}

.Vorschau{
  height:88.07157057654075vh;
  left: 59.895833333333336vw;
}

.entwuerfe {
  scrollbar-width: 0;
  width: 27.604166666666668vw;
  height: 97.4155069582505vh;
  position: absolute;
  top: 19.880715705765407vh;
  right: 4.1vw;
  border-radius: 7px;
  background-color: #1b1b1b;
}

.DraftBox::-webkit-scrollbar{
  width:10px;
}

.DraftBox::-webkit-scrollbar-track{
  background: white;
  border-radius: 20px;
}

.DraftBox::-webkit-scrollbar-thumb{
  background-color: black;
  border-radius: 20px;
  border: 4px solid black;
}


.DraftBox{
  display: flex;
  flex-direction: column;
  position: relative;
  overflow-y: auto;
  overflow-x: hidden;
  width: 26.041666666666668vw;
  height: 92.44532803180914vh;
  position: relative;
  top: -1.9880715705765408vh;
  left: 0.78125vw;
}

.nextEventBox1 {
  width: 44.791666666666664vw;
  height: 25.24850894632207vh;
  background-color: #0e0e0e;
  position: relative;
  border-radius: 7px;
}

.EventBox {
  width: 47.916666666666664vw;
  scrollbar-width: none;
  scroll-behavior: smooth;
  height: 88.46918489065607vh;
  display: flex;
  flex-direction: column;
  position: relative;
  top: 0px;
  left: -0.2604166666666667vw;
  overflow-y: scroll;
  overflow-x: hidden;
}

.EventBox > #item {
  margin: 25px;
}

.DraftBox > #draftItem{
  margin: 10px;
}

.eventDraft {
  width: 0.2604166666666667vw;
  height: 14.910536779324056vh;
  border-radius: 7px;
  background-color: #0e0e0e;
  position: relative;
  left: -0.5208333333333334vw;
}

.nextEventDatumDraft {
  color: #0d6efd;
  z-index: 1;
  position: absolute;
  right: 1.0416666666666667vw;
  top: 6.461232604373757vh;
  font-size: 0.5208333333333334vw;
}

.nextEventDatumNumDraft {
  color: white;
  font-size: 0.5208333333333334vw;
  z-index: 1;
  position: absolute;
  right: 0.46875vw;
  top: 7.952286282306163vh;
}

.eventDetailsBoxDraft {
  position: absolute;
  width: 26.041666666666668vw;
  height: 7.952286282306163vh;
  background-color: #0e0e0e;
  bottom: 0px;
  border-radius: 7px;
}

#geteiltIconDraft {
  color: #656565;
  z-index: 1;
  position: absolute;
  top: 11.928429423459244vh;
  left: 5.729166666666667vw;
  font-size: 0.5729166666666666vw;
}

#aufrufeIconDraft {
  color: #656565;
  z-index: 1;
  position: absolute;
  font-size: 0.5729166666666666vw;
  left: 0.78125vw;
  top: 4.970178926441352vh;
}

#gespeichertIconDraft {
  font-size: 0.5729166666666666vw;
  color: #656565;
  z-index: 1;
  position: absolute;
  top: 11.928429423459244vh;
  left: 11.197916666666666vw;
}

#nextEventAufrufeDraft {
  color: #656565;
  z-index: 1;
  position: absolute;
  top: 3.131212723658052vh;
  left: 1.8229166666666667vw;
  font-size: 0.625vw;
}

#nextEventSharedDraft {
  color: #656565;
  z-index: 1;
  position: absolute;
  top: 10vh;
  left: 6.666666666666667vw;
  font-size: 0.625vw;
}

#nextEventSavedDraft {
  color: #656565;
  z-index: 1;
  position: absolute;
  top: 10.03976143141153vh;
  left: 12.03125vw;
  font-size: 0.625vw;
}

#editIconEvent {
  color: white;
  position: relative;
  top: 35%;
  left: 50%;
  transform: translate(-50%,-50%);
}

.editIconBtn {
  width: 1.3020833333333333vw;
  height: 2.485089463220676vh;
  border-radius: 7px;
  border: 1px solid white;
  /* background-color: white; */
  position: relative;
  left: 20.3125vw;
  top: 1.4910536779324055vh;
}

.topBar {
  width: 78.64583333333333vw;
  height: 14.910536779324056vh;
  background-color: #0e0e0e;
  border-radius: 7px;
  position: absolute;
  right: 2.6041666666666665vw;
  top: 4.970178926441352vh;
}

.LiveBtn {
  cursor: pointer;
  width: 20vw;
  height: 5.467196819085487vh;
  border-radius: 5px;
  background-color: #23cb70;
  color: black;
  font-family: Montserrat_Regular;
}

#liveBtnText {
  position: relative;
  top: 50%;
  left: 12%;
  /* transform: translate(-50%,-50%); */
  font-size: 0.7291666666666666vw;
}

.editBtn {
  cursor: pointer;
  width: 20vw;
  height: 5.467196819085487vh;
  border-radius: 5px;
  background-color: #0d6efd;
  text-align: center;
  color: white;
  font-family: Montserrat_Regular;
}

#editBtnText {
  position: relative;
  top: 49%;
  /* left: 50%;
  transform: translate(-50%,-50%); */
  font-size: 0.7291666666666666vw;
  text-decoration: none;
}

.Details {
  width: 50.416666666666664vw;
  height: 69vh;
  background-color: #0e0e0e;
  position: relative;
  top: 39.860834990059644vh;
  left: 3.0208333333333335vw;
  border-radius: 7px;
}

.SidebarEventErstellen {
  display: flex;
  flex-direction: column;
  color: white;
  background-color: #1b1b1b;
  border-radius: 7px;
  padding: 20px;
}

.SidebarEventErstellen > .itemSideBar {
  margin: 6px;
}

.itemSideBar {
  margin: 6px;
  height: 40px;
  display: flex;
  align-items: center;
  /* background-color: black; */
}

.Icon {
  width: 40px;
  height: 40px;
  background-color: #686767;
  border-radius: 50%;
  display: flex;
  align-items: center;
  justify-content: center;
}

.unterIcon {
  width: 30px;
  height: 30px;
  background-color: #686767;
  border-radius: 50%;
  margin-left: 50px;
  display: flex;
  align-items: center;
  justify-content: center;
}

.InInput {
  color: white;
  left: 0.625vw;
  top: -4.174950298210735vh;
}

#iconBeschreibung {
  color: white;
  font-family: Montserrat_Light;
  margin-left: 10px;
}

#unterIconBeschreibung {
  color: white;
  font-family: Montserrat_Light;
  font-weight: lighter;
  margin-left: 10px;
}

.InputBox {
  background-color: #1b1b1b;
  border-radius: 7px;
  padding: 20px;
}

.box-title {
  display: flex;
  align-items: center;
  margin-bottom: 10px;
}

.ErstellenInputs {
  margin: 40px 0;
  gap: 20px;
  display: flex;
  flex-direction: column;
}

.InputsItem {
}

.InputIcon {
  min-width: 40px;
  width: 40px;
  height: 40px;
  background-color: #686767;
  border-radius: 50%;
  color: white;
  display: flex;
  align-items: center;
  justify-content: center;
}

.InputName {
  color: white;
  font-family: Montserrat;
}

.Unter {
  font-size: 0.7291666666666666vw;
  top: 0vh;
  left: 2.8645833333333335vw;
}

.hochscrollBtn {
  width: 2.6041666666666665vw;
  height: 2.6041666666666665vw;
  border-radius: 50%;
  position: fixed;
  bottom: 2.982107355864811vh;
  right: 3.6458333333333335vw;
  background-color: #0d6efd;
  cursor: pointer;
}

#hochscrollIcon {
  color: white;
  font-size: 1.3020833333333333vw;
  position: relative;
  top: 0.9940357852882704vh;
  left: 0.7291666666666666vw;
}

.InputDetails {
  color: #929292;
  font-family: Montserrat_Light;
  font-weight: 400;
  font-size: 70%;
}

.DetailsInputBox {
  width: 33.854166666666664vw;
  height: 96.42147117296223vh;
  position: relative;
  left: 50%;
  top: 46%;
  transform: translate(-50%, -50%);
  display: flex;
  flex-direction: column;
}

.UnterDet {
  font-size: 0.625vw;
  left: 2.8645833333333335vw;
  top: 2.485089463220676vh;
}

.DetailsInputBox > .DetailsInputBoxItem {
  margin: 10px;
}

.DetailsInputBoxItem {
  width: 33.854166666666664vw;
  border-radius: 7px;
  background-color: #3a3a3a;
  position: relative;
  left: -0.5208333333333334vw;
  top: -0.9940357852882704vh;
}

.BildBorder{
  width: 100%;
  height: auto;
  min-height: 150px;
  flex-grow: 1;
  border-radius: 5px;
  border: 1px solid;
  border-color: white;
  z-index: 0;
  position: relative;
}

#output_image{
  width: 100%;
  height: 100%;
  border-radius: 5px;
  color: white;
  visibility: hidden;
  font-family: Montserrat_Regular;
  position: absolute;
}

.UploadBtn {
  background-color: white;
  border-radius: 5px;
  border-style: none;
  z-index: 1;
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  padding: 5px 10px;
}

#UploadBtn {
  visibility: visible;
}

#upload {
}

.imgInp {
  display: none;
}
#recoSize {
  color: #858484;
  font-size: 70%;
}

.TextInput {
  width: 100%;
  height: 40px;
  border-radius: 7px;
  border: none;
  text-indent: 10px;
  background-color: #2c2c2c;
  color: white;
  font-family: Montserrat_Light;
}

.Half {
  left: 3.6458333333333335vw;
  width: 9.791666666666666vw;
  margin: 10px;
}

.Fest {
  top: -0.9940357852882704vh;
}

#festesEnde {
  color: white;
}

#festesDatum {
  display: none;
}

/* The switch - the box around the slider */
.switch {
  position: relative;
  display: inline-block;
  width: 50px;
  height: 30px;
}

/* Hide default HTML checkbox */
.switch input {
  opacity: 0;
  width: 0;
  height: 0;
}

/* The slider */
.slider {
  position: absolute;
  cursor: pointer;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  background-color: #ccc;
  -webkit-transition: 0.4s;
  transition: 0.4s;
}

.slider:before {
  position: absolute;
  content: "";
  height: 20px;
  width: 20px;
  left: 5px;
  bottom: 5px;
  background-color: white;
  -webkit-transition: 0.4s;
  transition: 0.4s;
}

input:checked + .slider {
  background-color: #2196f3;
}

input:focus + .slider {
  box-shadow: 0 0 1px #2196f3;
}

input:checked + .slider:before {
  -webkit-transform: translateX(20px);
  -ms-transform: translateX(20px);
  transform: translateX(20px);
}

/* Rounded sliders */
.slider.round {
  border-radius: 34px;
}

.slider.round:before {
  border-radius: 50%;
}

#adresse-hidden {
  visibility: hidden;
  position: relative;
  top: -1.0416666666666667vw;
}

.selectMinAlter {
  display: inline-flex;
  flex-wrap: wrap;
}

.selectMinAlter > .selectMinAlterItem {
  margin: 5px;
}

.selectMinAlterItem {
  cursor: pointer;
  background-color: #2c2c2c;
  width: 100px;
  height: 30px;
  border-radius: 80px;
  display: flex;
  align-items: center;
  justify-content: center;
}

#alterNum {
  color: white;
  font-family: Montserrat_Light;
  font-weight: lighter;
}

#minAlterInp {
  visibility: hidden;
  border-radius: 80px;
  width: 2.6041666666666665vw;
  border: none;
  text-indent: 17px;
  border: 2px solid #0d6efd;
}

#mutti-zettel {
  width: 7.291666666666667vw;
  max-lines: 1;
  text-overflow: 2;
  font-size: 0.78125vw;
  color: white;
  position: relative;
  left: -34.895833333333336vw;
  top:  12.2vh;
}

#SpecialsInput {
  visibility: hidden;
  left: 1.0416666666666667vw;
}

#DresscodeInput {
  visibility: hidden;
}

#oder {
  color: white;
  font-family: Montserrat;
  position: relative;
  top: -10.366492146596858vh;
  left: 15.625vw;
}

#euroIcon {
  color: white;
  position: relative;
  left: 12.5vw;
  top: -5.968586387434555vh;
}

#InputTitel {
  z-index: 0;
  color: #858484;
  font-family: Montserrat_Light;
  font-weight: lighter;
  font-size:  0.625vw;
  position: relative;
  top: -4.18848167539267vh;
  left: 4.6875vw;
}

.InputTitel {
  visibility: hidden;
  z-index: 0;
  color: #858484;
  font-family: Montserrat_Light;
  font-weight: lighter;
  font-size: 0.625vw;
  position: relative;
  top: -4.18848167539267vh;
  left: 4.6875vw;
}

#freierEintrittBtn, #noDresscodeBtn {
  cursor: pointer;
  border-radius: 7px;
  background-color: white;
  padding: 5px 10px;
}

#freierEintrittText {
  position: relative;
  top: 45%;
  left: 63.5%;
  transform: translate(-50%, -50%);
  font-family: Montserrat;
  font-size: 0.9687500000000001vw;
}

#abendkasse {
  color: white;
  font-family: Montserrat;
  font-size: 0.8333333333333334vw;
  position: relative;
  top: -11.518324607329843vh;
  left: 4.166666666666667vw;
}

#abendkasseDetails {
  visibility: hidden;
  color: #929292;
  font-family: Montserrat_Light;
  font-weight: lighter;
  font-size: 0.6770833333333334vw;
  position: relative;
  top: -12.041884816753926vh;
  left: 4.166666666666667vw;
}

#oeffnungsZeit {
  position: relative;
  top: -10.471204188481675vh;
  left: 0.5208333333333334vw;
}

#abendkassePreis {
  position: relative;
  top: -21.047120418848166vh;
  left: 19.322916666666668vw;
}

.unterTitelDiv {
  color: white;
  font-size: 0.9375vw;
  font-family: Montserrat_SemiBold;
  font-weight: lighter;
  position: relative;
  top: -17.9vh;
  left: 4.166666666666667vw;
}

#LocationIcon{
  font-size:0.8vw;
  color: #656565;
  z-index: 1;
  position: absolute;
  top: 21.868787276341948vh;
  left: 18vw;  
}

#MVZ{
  visibility: hidden;
}

#Zusatz{
  visibility: hidden;
}

#beschreibung{
  color: white;
  font-family: Montserrat;
  border-radius: 7px;
  background-color: #2c2c2c;
  width: 100%;
  min-height: 150px;
}

#ausgewaehlteGenres{
  color: white;
  font-size: 85%;
  font-family: Montserrat_Light;
  font-weight: lighter;
}

.ButtonsBox{
  display: flex;
  flex-direction: row;
  width:30vw;
  /* height:10vh; */
  position: relative;
  top: 5vh;
  right: -48vw;
  left:auto;
}


.ButtonsBox > .Button{
  margin: 20px;
}
.genreButton{
  width: 100px;
  height: 30px;
  border-radius: 30px;
  background-color: #0d6efd;
  text-align: center;
  color: white;
  display: flex;
  align-items: center;
  justify-content: center;
}

.GenreBox{
  width: 100%;
  display: flex;
  gap: 10px;
}

.deleteDraftBtn{
  cursor: pointer;
  width: 1.3020833333333333vw;
  height: 2.6178010471204187vh;
  border-radius: 7px;
  border: 1px solid white;
  /* background-color: red; */
  position: relative;
  left: 17.708333333333332vw;
  top: -1.256544502617801vh;
}

.DeleteBtn{
  cursor: pointer;
  width: 20vw;
  height: 5.467196819085487vh;
  border-radius: 5px;
  background-color: red;
  color: white;
  font-family: Montserrat_Regular;
}


#deleteIcon{
  color: white;
  position: relative;
  top: 40%;
  left: 50%;
  transform: translate(-50%,-50%);
}

#genreDetails{
  width: 27.083333333333332vw;
  height: 14.910536779324056vh;
  position: relative;
  background-color: #1d1d1d;
  top: 6.958250497017892vh;
  left: 1.5625vw;
  border-radius: 7px;
}

.GenreBoxEvent{
  width: 18.75vw;
  height: 9.940357852882704vh;
  /* background-color: #0d6efd; */
  position: relative;
  top:3.9761431411530817vh;
  left: 1.0416666666666667vw;
}

.GenreItem{
  width: 2.0833333333333335vw;
  height: 1.9880715705765408vh;
  background-color: #23cb70;
}

.genreText{
  color: white;
  position: relative;
  margin-left: 10px;
}

.BeschreibungDetails{
  width: 27.083333333333332vw;
  height: 19.880715705765407vh;
  background-color: #1d1d1d;
  border-radius: 7px;
  position: relative;
  top: 8.449304174950298vh;
  left: 1.5625vw;
}

.beschreibungText{
  overflow: scroll;
  color: white;
  overflow-x: hidden;
  font-family: Montserrat_Light;
  font-weight: 200;
  width: 90%;
  height: 11.928429423459244vh;
  position: relative;
  /* top: 4.473161033797217vh;
  left: 1.5625vw; */
  top:41%;
  left: 50%;
  transform: translate(-50%,-50%);
  font-size: 0.5729166666666666vw;
}

.MinAlterDetails{
  width: 10.416666666666666vw;
  height: 14.910536779324056vh;
  background-color: #1d1d1d;
  position: relative;
  top: -31.3vh;
  left: 31.25vw;
  border-radius: 7px;
  color: white;
  text-align: center;
}

.min-alter-text{
  position: relative;
  top:-3vh;
  font-size: 4.166666666666667vw;
  color: red;
  font-family: Montserrat;
  
}

.Start_End_Time{
  width:10.416666666666666vw;
  height:19.880715705765407vh;
  background-color:#1d1d1d;
  border-radius: 7px;
  position: absolute;
  top: 29vh;
  left: 31.25vw;
  
}
 

.dateText{
  color: #0d6efd;
  position: relative;
  top: 0.2vh;
  text-align: center; 
  font-family: Montserrat_Regular;
  font-size: 0.9375vw;
}

.AdresseDetails{
  width: 27.083333333333332vw;
  height:14.910536779324056vh;
  background-color:#1d1d1d;
  border-radius: 7px;
  position: absolute;
  top: 52.3vh;
  left:1.5625vw
}

#firebaseImg{
    border-radius: 5px;
    position: absolute;
    left: 3.3854166666666665vw;
    top: 8.34990059642147vh;
    color: white;
    font-family: Montserrat_Regular;
}

.SettingsIcon{
  background-color: #858484;
  color: white;
  width: 4.712041884816754vh;
  height: 4.712041884816754vh;
  border-radius: 50%;
  border: none;
}

.IconsBoxSearch{
  display: flex;
  position: relative;
  top: 50%;
  transform: translate(-0%,-50%);
  margin-right: auto;
  margin-left: 85%;
}

.IconsBoxSearch > .IconSearch {
  margin: 10px;
}

.SideBarModal{
  width: 20%;
  height: 100%;
  background-color: #0d0d0d;
  border-radius: 7px;
  /* display: flex; */
  /* text-align: center; */
}

.SideBarModalBox{
  width: 100%;
  height: 93%;
  position: relative;
  bottom: -2.3vh;

  display: flex;
  flex-direction: column;
  /* text-align: center; */
}

.SideBarModalBox > .SideBarModalItem {
  margin: 15px;
}

.SideBarModalItem{
  width: 100%;
  height: 10%;
  position: relative;
  left: -4.6%;
  color: rgba(211, 211, 211, 10);
  cursor: pointer;

}

.Active{
  background-color:rgb(29, 29, 29);
  color: #0d6efd;
  cursor: not-allowed;
}

.SideBarModalItemText{
  font-size: 0.7291666666666666vw;
  position: relative;
  top: 10%;
  left: 25%;
  font-family: Montserrat;
}

.SideBarModalItemTextActive{
  font-size: 0.8333333333333334vw;
  position: relative;
  top:10%;
  font-family: Montserrat;
  left: 25%;
}



.IconActive{
  font-size:1.5em;
  position: relative;
  top: -40%;
  left: 5%;
}

.IconNotActive{
  position: relative;
  top: -37%;
  left:5%;
}

.ModalContent{
  width: 68.03vw;
  border-radius: 7px;
  height: 80vh;
  /* background-color: #0d6efd; */
  position: relative;
  top: -100%;
  left: 100%;
  /* opacity: 30%; */
}

.ModalContentTitle{
  color: white;
  position: relative;
  top: 9.5vh;
  left: 5vw;
  font-family: Montserrat_SemiBold;

}


.v4{
  width: 85%;
  height: 0.2vh;
  border-radius: 7px;
  background-color: #686767;
  position: relative;
  margin-left: auto;
  margin-right: auto;
  top: 12%;
}

.ModalContentTitleDetails{
  color: #d3d3d3;
  position: relative;
  top: 10vh;
  left: 5vw;
  font-family: Montserrat_Light;
}

.newPassw{
  cursor: pointer;
  width:12vw;
  height: 5vh;
  background-color: #0d6efd;
  text-align: center;
  border-radius: 7px;
  position: relative;
  top: 35%;
  left: 5vw;
}

#SettingIcon{
  font-size: 1.25vw;
  position: relative;
  top: 45%;
  left: 50%;
  transform: translate(-50%,-50%);
}

#user-circle-icon{
  font-size:1.25vw;
  position: relative;
  top: 17%;
  left:-22%;
}


#userIconPic{
  width:4.712041884816754vh;
  height:4.712041884816754vh;
  border-radius: 50%;
  object-fit: cover;
  position: relative;
  left:0px;
  top:-2.3vh
}

#LogoutBtnIcon{
  position: relative;
  top:50%;
  left:50%;
  transform: translate(-50%,-50%);
  font-size:1.35vw
}

#EventBanner{
  border-radius:7px;
  height:20.278330019880716vh;
  width:44.791666666666664vw;
  top: 0px;
  object-fit: cover;
}

#EventBannerDraft{
  border-radius:7px;
  height:7.952286282306163vh;
  width:26.041666666666668vw;
  position: absolute;
  top: 0px;
  left:-19px
}
/* End of Content Style */

/* Start of Responsiveness */


@media only screen and (max-width: 2828px ){
  #content-titel-text{
    left:2.4vw;
  }

  
  
}


@media only screen and (max-width:1500px){
  #SettingIcon{

  }
}

@media only screen and (max-width:1500px) and (max-height:1000px) {
  .uebersichtTitelDetails{
    left:14vw;
  }
  .nextEventBox{
    top: 21.7vh;
  }
  .eventErstellen{
    top: 21.7vh;
  }

  /* #userIconPic{
    top: -3.5vh;
  } */

  /* #SettingIcon{
    top: 35%;
    left:52%
  }

  #LogoutBtnIcon{
    top: 45%;
    left:53%
  } */




}

@media only screen and (max-width:2055px){
  #IconInField{
    top: 45%;
  }
  #UnterIconInField{
    top: 40%;
  }
}
@media only screen and (max-width:1785px){
  #IconInField{
    top: 40%;
  }
  #UnterIconInField{
    top:35%
  }
}

@media only screen and (max-width:1600px){
  #IconInField{
    top: 35%;
  }
  #UnterIconInField{
    top: 30%;
  }
}

@media only screen and (max-width:1440px){ 
  #IconInField{
    top: 32%;
  }
  #UnterIconInField{ 
    top: 22%;
  }
}

@media only screen and (max-width:1060px){
  #IconInField{
    top: 22%;
  }
  #UnterIconInField{
    top: 10%;
  }
  
}

.flex-gap-10 {
  display: flex;
  gap: 10px;
  align-items: center;
}

.submit-button {
  cursor: pointer;
  width: 100%;
  background-color: #0d6efd;
  color: white;
  text-align: center;
  border-radius: 5px;
  border-style: none;
  padding: 15px;
  margin-top: 15px;
}

.submit-button:disabled {
  cursor: not-allowed;
  background-color: gray;
}

.input-top-label {
  font-family: Montserrat_Light;
  font-size: 70%;
  font-weight: 400;
  margin-top: 10px;
  margin-bottom: 5px;
  color: #929292;
  font-size: 70%;
}